import { defineStore, acceptHMRUpdate } from 'pinia'

export const useSubStore = defineStore('Sub', {
    state: () => ({
        // Email to be unsubscribed
        email: null,

        // Error while processing subscriber
        error: null,

        // Reason for unsubscribing
        reason: null,
    }),
    getters: {},
    actions: {
        // Unsubscribe
        async unsubscribe() {
            //const GqlInstance = useGql()

            try {
                const data = await $fetch(`/api/subscriber/${this.email}`)
                if (data && data.subscribers.totalCount > 0) {
                    if (data.subscribers.edges[0].node.status.name === 'Active') {
                        try {
                            const resp = await $fetch('/api/subscriber/update', {
                                method: 'POST',
                                body: {
                                    id: data.subscribers.edges[0].node.id,
                                    status: '/api/statuses/7',
                                    reason: this.reason,
                                    clientMutationId: 'updated-subscriber-successfully',
                                },
                            })
                            /*const resp = await GqlInstance({
                                operation: 'UpdateSubscriber',
                                variables: {
                                    id: data.subscribers.edges[0].node.id,
                                    status: '/api/statuses/7',
                                    reason: this.reason,
                                    clientMutationId: 'updated-subscriber-successfully',
                                },
                            })*/
                        } catch (e) {
                            this.error = e.message
                            console.log('error updating subscriber = ', e.message)
                        }
                    }
                } else {
                    this.error = e.message
                    console.log('no API issue but cannot read data = ', e.message)
                }
            } catch (e) {
                this.error = e.message
                console.log('error fetching subscriber = ', e.message)
            }
        },

        // Subscribe
        async subscribe() {
            //const GqlInstance = useGql()

            try {
                // const data = await GqlInstance('GetSubscriberByEmail', { email: this.email })
                const data = await $fetch(`/api/subscriber/${this.email}`)
                if (data && data.subscribers.totalCount > 0) {
                    if (data.subscribers.edges[0].node.status.name === 'Unsubscribed') {
                        try {
                            const data = await $fetch('/api/subscriber/update', {
                                method: 'POST',
                                body: {
                                    id: data.subscribers.edges[0].node.id,
                                    status: '/api/statuses/1',
                                    reason: null,
                                    clientMutationId: 'updated-subscriber-successfully',
                                },
                            })
                            /*const result = await GqlInstance({
                                operation: 'UpdateSubscriber',
                                variables: {
                                    id: data.subscribers.edges[0].node.id,
                                    status: '/api/statuses/1',
                                    reason: null,
                                    clientMutationId: 'updated-subscriber-successfully',
                                },
                            })*/
                        } catch (e) {
                            this.error = e.message
                            console.log('subscribe: error updating subscriber = ', e.message)
                        }
                    }
                } else {
                    try {
                        const data = await $fetch('/api/subscriber/create', {
                            method: 'POST',
                            body: {
                                status: '/api/statuses/1',
                                firstName: null,
                                lastName: null,
                                email: this.email,
                                clientMutationId: 'created-subscriber-successfully',
                            },
                        })
                        /*const result = await GqlInstance({
                            operation: 'CreateSubscriber',
                            variables: {
                                status: '/api/statuses/1',
                                firstName: null,
                                lastName: null,
                                email: this.email,
                                clientMutationId: 'created-subscriber-successfully',
                            },
                        })*/
                    } catch (e) {
                        this.error = e.message
                        console.log('subscribe: error creating subscriber = ', e.message)
                    }
                }
            } catch (e) {
                this.error = e.message
                console.log('subscribe: error fetching subscriber = ', e.message)
            }
        },
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSubStore, import.meta.hot))
}
